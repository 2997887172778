<template>
  <div :class="baseClass + '-mobile-register-page'">
    <div :class="baseClass+'-linear-border-container'">
      <div :class="baseClass+'-linear-border-content'">
        <div :class="baseClass+'-container-title'">
          <div class="container">Create A FREE Account</div>
        </div>
        <div :class="baseClass + '-container-content'">
          <div class="title">{{title}}</div>
          <div class="row" v-for="item,index of formNode" :key="index">
            <div class="label">{{item.label}}</div>
            <input class="input" :type="item.type" lang="en" />
          </div>
          <div class="msg" @click="current = !current">
            <img class="ck"  :src="current?'/static/actions/ck-1.png':'/static/actions/ck.png'" />
            <div class="text">I agree to the website terms & conditions.</div>
          </div>
          <div class="btn">
            <img src="/static/actions/register_btn-1.png"  @click="$router.push('/mobilelogin')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      current:false,
      title: "Register Now",
      formNode: [
        { label: "First Name:", type: "text", formKey: "" },
        { label: "Last Name:", type: "text", formKey: "" },
        { label: "Email:", type: "text", formKey: "" },
        { label: "Username:", type: "text", formKey: "" },
        { label: "Password:", type: "password", formKey: "" },
        { label: "Retype Password:", type: "password", formKey: "" },
        { label: "Birthday:", type: "date", formKey: "" },
        { label: "Phone:", type: "text", formKey: "" },
        { label: "Address:", type: "text", formKey: "" },
        { label: "Zip Code:", type: "text", formKey: "" },
        { label: "Gender:", type: "text", formKey: "" },
      ]
    }
  }
}
</script>
<style lang="scss">
.#{$baseClass}-mobile-register-page {
  padding-bottom: 50px;
  .msg{
    display: flex;
    align-items: center;
    padding: 16px 0 12px;
    .ck{
      width: 12px;
      height: 12px;
    }
    .text{
      margin-left: 5px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .#{$baseClass}-linear-border-container {
    position: relative;
    margin: 36px 12px 0;
    padding: 1px;
    border-radius: 4px;
    .#{$baseClass}-linear-border-content {
      background: #2b89ff;
     border-radius: 4px;
    }
  }
  .#{$baseClass}-container {
    &-content {
      padding-bottom: 20px;
      padding-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 12px;
      }
      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 8px;
        }
        .input {
          width: 100%;
          height: 24px;
          background: #006cf3;
          border-radius: 4px;
          border: 1px solid #ffffff;
          width: 280px;
          margin-bottom: 12px;
          color: #fff;
        }
      }
      .btn {
        padding-top: 4px;
        margin-bottom: 8px;
        img {
          width: 98px;
          cursor: pointer;
        }
      }
      .forgot {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
    &-title {
      display: flex;
      justify-content: center;
      position: absolute;
      top: -20px;
      width: 100%;

      div {
        width: 296px;
        font-size: 20px;
        font-family: Arial-BoldMT, Arial;
        font-weight: 100;
        color: #ffffff;
        padding: 10px 0;
        background: linear-gradient(180deg, #fed03b 0%, #ff738d 100%);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

</style>